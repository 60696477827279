export default {
  install(app) {
    app.directive("tinymce", {
      async mounted(el, binding) {
        const script = document.createElement("script");
        script.src = "/tinymce/tinymce.min.js";
        script.onload = () => {
          const proxy = app.config.globalProperties;

          const getPublicSignature = async (directory) => {
            return new Promise((resolve, reject) => {
              proxy.$api.authorizationRequest
                .getPublicSignature(directory)
                .then((res) => {
                  resolve(res.result.data);
                })
                .catch((error) => {
                  console.log(error);
                  reject(error);
                });
            });
          };

          const getSuffix = (filename) => {
            let pos = filename.lastIndexOf(".");
            return pos !== -1 ? filename.substring(pos) : "";
          };

          const globalState = app.config.globalProperties.$globalState;

          tinymce.init({
            target: el,
            width: "100%",
            menubar: false,
            statusbar: false,
            font_formats:
              "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; Georgia=georgia,palatino; Times New Roman=times new roman,times; Verdana=verdana,geneva;",
            plugins: ["image", "code", "table", "link", "media"],
            toolbar:
              "blocks | fontsizeplus fontsizeinput fontsizeminus | bold italic forecolor | align | outdent indent | link | image | media | code | table",
            base_url: "/tinymce",
            suffix: ".min",
            automatic_uploads: true,
            link_default_target: "_blank",
            link_target_list: false,
            file_picker_types: "image file media",
            selector: "textarea",
            file_picker_callback: async (cb, value, meta) => {
              const input = document.createElement("input");
              input.setAttribute("type", "file");

              if (meta.filetype === "image") {
                input.setAttribute("accept", "image/*");
              } else if (meta.filetype === "file") {
                input.setAttribute("accept", "*/*");
              } else if (meta.filetype === "media") {
                input.setAttribute("accept", "video/*");
              }

              input.onchange = async function () {
                const file = this.files[0];
                let directory;

                if (meta.filetype === "image") {
                  directory = "interactions_image";
                } else if (meta.filetype === "file") {
                  directory = "interactions_file";
                } else if (meta.filetype === "media") {
                  directory = "interactions_video";
                }

                try {
                  const credentials = await getPublicSignature(directory);

                  const formData = new FormData();
                  formData.append(
                    "key",
                    `${credentials.dir}/${credentials.uuid}${getSuffix(
                      file.name
                    )}`
                  );
                  formData.append("OSSAccessKeyId", credentials.accessId);
                  formData.append("policy", credentials.policy);
                  formData.append("Signature", credentials.signature);
                  formData.append("success_action_status", "200");
                  formData.append("file", file);

                  const response = await fetch(credentials.host, {
                    method: "POST",
                    body: formData,
                  });

                  if (response.status === 200) {
                    const fileUrl = `${credentials.host}/${credentials.dir}/${
                      credentials.uuid
                    }${getSuffix(file.name)}`;

                    if (meta.filetype === "image") {
                      cb(fileUrl, { title: file.name });
                    } else if (meta.filetype === "file") {
                      // 插入下载链接
                      cb(fileUrl, {
                        text: file.name,
                        title: file.name,
                        download: true, // 设置为下载
                      });
                    } else if (meta.filetype === "media") {
                      cb(fileUrl, { source: fileUrl, title: file.name });
                    }
                  } else {
                    console.error("OSS upload error:", response.statusText);
                  }
                } catch (err) {
                  console.error("OSS upload error:", err);
                }
              };

              input.click();
            },
          });
        };
        document.head.appendChild(script);
      },
      unmounted(el) {
        if (typeof tinymce !== "undefined" && tinymce.get(el.id)) {
          tinymce.remove(el);
        }
      },
    });
  },
};
