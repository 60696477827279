import "core-js/modules/es.array.push.js";
import { ref, reactive, getCurrentInstance, onMounted, nextTick } from "vue";
import { Plus, EditPen } from "@element-plus/icons-vue";
import { useRouter, useRoute } from "vue-router";
import util from "@/util/util";

import { ElNotification } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
export default {
  name: "InstallCheck",
  components: {
    Plus,
    EditPen
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const value = ref("");
    const tab = ref(1);
    const dialogImageUrl = ref("");
    const dialogVisible = ref(false);
    const drawBoxShow = ref(false);
    const textarea1 = ref("");
    const uploadTable = ref(null);
    const ActivityName = ref("");
    const vendorName = ref("");
    const storeName = ref("");
    const uploadTableData = reactive([]);
    const installationStatusData = reactive([]);
    const ysNameInput = ref("");
    const ysPhoneInput = ref("");
    const remarkInput = ref("");
    const drawBase64 = ref("");
    const uploadResultPhotoData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: [],
      uploaded: []
    });
    const vendorId = route.params.vendorId;
    const activityId = route.params.activityId;
    const storeCode = route.params.storeCode;
    //请求获取所有安装点位列表
    const activityInstallationROPage = () => {
      let activityInstallationROPage = {
        activityId: activityId,
        storeCode: storeCode,
        vendorId: vendorId
      };
      proxy.$api.repairRequest.getActivityInstallation(activityInstallationROPage).then(function (response) {
        for (var i = 0; i < response.result.data.length; i++) {
          let demoJson = {
            signatureData: {
              host: ""
            },
            id: i,
            fileList: response.result.data[i].uploaded,
            data: {},
            urgentFlag: false,
            urgentText: null,
            locationInfo: response.result.data[i].locationInfo,
            activityInstallationId: response.result.data[i].activityInstallationId,
            installationStatusId: response.result.data[i].installationStatusId
          };
          uploadTableData.push(demoJson);
        }
      }).catch(function (error) {
        console.log(error);
      });
    };
    //预览图片
    const handlePictureCardPreview = uploadFile => {
      dialogImageUrl.value = uploadFile.url;
      dialogVisible.value = true;
    };
    //返回
    const goBack = () => {
      router.go(-1);
    };
    //开关变化触发的函数
    const switchChang = (a, index) => {
      // console.log(a,index)
      if (!a) {
        uploadTableData[index].urgentText = null;
      }
    };
    const signaturePad = ref();
    //画布撤销
    const undo = () => {
      signaturePad.value.clearSignature();
    };
    //画布完成
    const save = () => {
      const {
        isEmpty,
        data
      } = signaturePad.value.saveSignature();
      if (checkOrientation() == "landscape") {
        drawBase64.value = data;
        drawBoxShow.value = false;
      } else {
        rotateBase64(data).then(img => {
          drawBase64.value = img;
          drawBoxShow.value = false;
        });
      }
    };
    //旋转90度
    const rotateBase64 = data => {
      //传入需要旋转的base64图片
      return new Promise(resolve => {
        const imgView = new Image();
        imgView.src = data;
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const cutCoor = {
          sx: 0,
          sy: 0,
          ex: 0,
          ey: 0
        }; // 裁剪坐标
        imgView.onload = () => {
          const imgW = imgView.width;
          const imgH = imgView.height;
          const size = imgH;
          canvas.width = size * 2;
          canvas.height = size * 2;
          cutCoor.sx = size;
          cutCoor.sy = size - imgW;
          cutCoor.ex = size + imgH;
          cutCoor.ey = size + imgW;
          context.translate(size, size);
          context.rotate(Math.PI / 2 * 3);
          context.drawImage(imgView, 0, 0);
          const imgData = context.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
          canvas.width = imgH;
          canvas.height = imgW;
          context.putImageData(imgData, 0, 0);
          resolve(canvas.toDataURL("image/png"));
        };
      });
    };
    const goTab2 = async () => {
      await nextTick();
      const box = document.querySelector(".box");
      box.scrollTo(0, 0);
      let nextSwitch = true;
      for (let i = 0; i < uploadTableData.length; i++) {
        if (uploadTableData[i].urgentFlag && uploadTableData[i].urgentText == null) {
          nextSwitch = false;
          ElNotification({
            title: "错误",
            message: "请输入打开的缺陷描述！",
            type: "error",
            duration: 3000
          });
          break;
        }
      }
      if (nextSwitch) {
        tab.value = 2;
      }
    };
    const goTab1 = () => {
      tab.value = 1;
    };
    //根据Id获取验收状态
    const getInstallName = id => {
      for (let i in installationStatusData) {
        if (installationStatusData[i].installationStatusId === id) {
          return installationStatusData[i].installationStatusNameZh;
        }
      }
    };
    // 获取所有安装状态
    const getInstallationStatus = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.scheduleRequest.getInstallationStatus(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    //验收确认提交
    const submitAcceptResult = () => {
      // 判空
      if (ysNameInput.value === "") {
        ElNotification({
          title: "错误",
          message: "请输入验收人！",
          type: "error",
          duration: 3000
        });
        return;
      } else if (ysPhoneInput.value === "") {
        ElNotification({
          title: "错误",
          message: "请输入联系方式！",
          type: "error",
          duration: 3000
        });
        return;
      } else if (drawBase64.value === "" && (uploadResultPhotoData.uploaded == null || uploadResultPhotoData.uploaded.length == 0)) {
        ElNotification({
          title: "错误",
          message: "请输入签名或上传验收单！",
          type: "error",
          duration: 3000
        });
        return;
      }

      //参数
      let addArr = [];
      for (var i = 0; i < uploadTableData.length; i++) {
        let addObj = {};
        if (uploadTableData[i].fileList.length > 0) {
          //这个点位有照片
          if (uploadTableData[i].urgentFlag) {
            addObj.activityInstallationId = uploadTableData[i].activityInstallationId;
            addObj.defect = uploadTableData[i].urgentText;
            addArr.push(addObj);
          } else {
            addObj.activityInstallationId = uploadTableData[i].activityInstallationId;
            addObj.defect = null;
            addArr.push(addObj);
          }
        }
      }
      console.log(addArr);
      let activityInstallationSubmitAcceptRO = {
        acceptInfo: addArr,
        installationAcceptanceSign: drawBase64.value,
        installationResultContactMobile: ysPhoneInput.value,
        installationResultContactName: ysNameInput.value,
        remark: remarkInput.value,
        installationResultUploaded: uploadResultPhotoData.uploaded
      };
      console.log(activityInstallationSubmitAcceptRO);
      proxy.$api.repairRequest.submitAcceptResult(activityInstallationSubmitAcceptRO).then(function (response) {
        console.log(response);
        if (response.code == "200000") {
          if (response.result.dataCode === "202255") {
            ElNotification({
              title: "成功",
              message: response.result.promptMsg,
              type: "success",
              duration: 3000
            });
            router.replace({
              path: "/check-finish/"
            });
          } else {
            ElNotification({
              title: "失败",
              message: response.result.promptMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: response.result.promptMsg,
            type: "error",
            duration: 3000
          });
        }
      }).catch(function (error) {
        console.log(error);
        ElNotification({
          title: "失败",
          message: error,
          type: "error",
          duration: 3000
        });
      });
    };
    //获取活动信息
    const getActivity = async activityId => {
      return new Promise((resolve, reject) => {
        proxy.$api.repairRequest.getActivity(activityId, true).then(res => {
          if (res.code === "200000") {
            if (res.result.dataCode === "202154") {
              resolve(res.result.data);
            } else {
              ElNotification({
                title: "失败",
                message: res.result.promptMsg,
                type: "error",
                duration: 6000
              });
              resolve(null);
            }
          } else {
            ElNotification({
              title: "失败",
              message: res.msg,
              type: "error",
              duration: 6000
            });
            resolve(null);
          }
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };

    // 获取所有供应商
    const getVendor = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.repairRequest.getVendorAll(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    //获取所有店铺
    const getStore = async storeActivityVendorRO => {
      return new Promise((resolve, reject) => {
        proxy.$api.repairRequest.getStoreByActivityVendor(storeActivityVendorRO, true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const login = async () => {
      let params = {
        username: "temp_2024",
        password: "1",
        grant_type: "password"
      };
      return new Promise((resolve, reject) => {
        proxy.$api.userRequest.login(params).then(res => {
          sessionStorage.setItem("token", JSON.stringify(res));
          resolve(true);
        }).catch(error => {
          resolve(false);
        });
      });
    };
    onMounted(async () => {
      // if ((await login()) == false) {
      //   ElNotification({
      //     title: "失败",
      //     message: "临时用户验证失败",
      //     type: "error",
      //     duration: 3000,
      //   });
      //   return;
      // }
      // 获取所有安装状态
      let installationStatusList = await getInstallationStatus();
      if (installationStatusList == null) {
        return;
      }
      installationStatusData.push(...installationStatusList);
      let getVendorList = await getVendor();
      if (getVendorList == null) {
        return;
      }

      // 供应商名字
      for (let i in getVendorList) {
        if (getVendorList[i].vendorId == vendorId) {
          vendorName.value = getVendorList[i].vendorName;
        }
      }

      //活动名字
      let getActivityName = await getActivity(activityId);
      ActivityName.value = getActivityName.activityName;
      let storeActivityVendorRO = {
        activityId: activityId,
        vendorId: vendorId
      };
      let storeList = await getStore(storeActivityVendorRO);
      if (storeList == null) {
        return;
      }
      for (let store of storeList) {
        if (store.storeCode == storeCode) {
          storeName.value = store.storeName;
        }
      }
      activityInstallationROPage();
    });

    // 上传图片数量限制
    const maxNum = 3;
    //获取文件签名等信息
    const getSignature = async directory => {
      return new Promise((resolve, reject) => {
        proxy.$api.repairRequest.getSignature(directory).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          reject(error);
        });
      });
    };
    //上传文件之前
    const handleBefore = async file => {
      debugger;
      uploadResultPhotoData.signatureData = await getSignature("__temporary");
      let filename = file.name;
      let fullName = uploadResultPhotoData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadResultPhotoData.signatureData.dir + fullName;
      uploadResultPhotoData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadResultPhotoData.signatureData.policy,
        OSSAccessKeyId: uploadResultPhotoData.signatureData.accessId,
        signature: uploadResultPhotoData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadResultPhotoData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    // 文件上传成功
    const handleStoreOnSuccess = async (response, file, files) => {
      if (file.status === "success") {
        let uploadedRO = {
          fileName: uploadResultPhotoData.data.name,
          uploadedName: uploadResultPhotoData.data.key,
          uid: file.uid
        };
        file.uploadedRO = {};
        file.uploadedRO = uploadedRO;
        uploadResultPhotoData.uploaded.push(uploadedRO);
      }
    };
    //超出文件限制数量
    const handleExceed = () => {
      ElNotification({
        title: "失败",
        message: "最多上传" + maxNum + "张图片",
        type: "error",
        duration: 3000
      });
    };
    const checkOrientation = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        //竖屏
        return "portrait";
      } else if (window.matchMedia("(orientation: landscape)").matches) {
        return "landscape";
      }
    };
    return {
      value,
      dialogImageUrl,
      dialogVisible,
      textarea1,
      uploadTable,
      uploadTableData,
      tab,
      ysNameInput,
      ysPhoneInput,
      remarkInput,
      drawBoxShow,
      signaturePad,
      drawBase64,
      installationStatusData,
      ActivityName,
      vendorName,
      storeName,
      undo,
      save,
      goBack,
      handlePictureCardPreview,
      goBack,
      switchChang,
      goTab2,
      goTab1,
      submitAcceptResult,
      getInstallName,
      uploadResultPhotoData,
      maxNum,
      handleBefore,
      handleStoreOnSuccess,
      handleExceed
    };
  }
};