import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["innerHTML"];
import { ref, reactive, onMounted, getCurrentInstance, h, nextTick, createVNode, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import { ElNotification } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
export default {
  __name: 'ReportContent',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const reportContent = ref("");
    onMounted(async () => {
      debugger;
      let activityStateItemContentRO = {
        activityStatsItemId: route.params.id
      };
      debugger;
      reportContent.value = await getActivityStatsItemContentRequest(activityStateItemContentRO);
    });
    const getActivityStatsItemContentRequest = async activityStateItemContentRO => {
      return new Promise((resolve, reject) => {
        proxy.$api.activityStatsRequest.getActivityStatsItemContent(activityStateItemContentRO).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        innerHTML: reportContent.value
      }, null, 8, _hoisted_1);
    };
  }
};